.mission-outer-wrapper {
  //   border: 3px solid red;
  .mission-wrapper {
    background-color: #f5f5f5;
    height: 100%;
    padding: 10px;

    .row {
      .mission {
        .mission-btn {
          float: left;
          margin-left: 100px;
          margin-top: 20px;
          margin-bottom: 10px;
          border: none;
          outline: none;
          font-size: 18px;
          font-weight: 500;
          color: #0f3787;
          padding: 10px;
          width: 10%;
          cursor: default;
          border-bottom: 1px solid #0f3787;
        }
      }

      .content-wrapper {
        // border: 1px solid green;

        .container {
          background-color: #66a3c0;

          .mission-inner {
            padding: 3rem;
            height: 100%;
            max-width: 100%;
            position: relative;
            background-image: linear-gradient(
                rgba(0, 0, 0, 0.8),
                rgba(0, 0, 0, 0.7)
              ),
              url(../../assets/img/mission-hero-img.jpg);
            border-radius: 10px;
            background-size: cover;
            background-repeat: no-repeat;
            margin-bottom: 4.8rem;

            .header-container {
              height: auto;
              padding: 10px;

              .mission-heading {
                color: #fff;
                font-size: 3.6rem;
                margin-bottom: 3.2rem;
                line-height: 1;
                padding: 10px;
              }

              .para-one {
                font-size: 2rem;
                line-height: 1.6;
                color: #fff;
                text-align: left;
              }
            }
          }

          .section-psalm-67 {
            padding-top: 3.2rem;
            padding-bottom: 3.2rem;

            .container {
              .heading-secondary {
                color: #000;
                font-size: 2.4rem;
                font-weight: 800;
                line-height: 1;
                margin-bottom: 3.2rem;
                text-align: left;
              }

              .verses {
                display: flex;
                flex-direction: column;
                gap: 1rem;
                // border: 1px solid;

                .verse {
                  text-align: left;
                  margin-bottom: 1.6rem;

                  .para-two {
                    font-size: 2rem;
                    line-height: 1.6;
                    color: #000;
                    text-align: left;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
