.story-outer-wrapper {
  //   border: 3px solid red;
  .story-wrapper {
    // border: 1px solid;
    background-color: #f5f5f5;
    height: 100%;
    padding: 10px;

    .row {
      .story {
        margin-bottom: 10px;

        .story-btn {
          float: left;
          margin-left: 100px;
          margin-top: 20px;
          margin-bottom: 10px;
          border: none;
          outline: none;
          font-size: 18px;
          font-weight: 500;
          color: #0f3787;
          padding: 10px;
          width: 10%;
          cursor: default;
          border-bottom: 1px solid #0f3787;
        }
      }

      .story-main-inner {
        .section-story {
          padding-top: 3.2rem;
          border-radius: 9px;
          color: #0f3787;

          .container {
            background-color: #66a3c0;

            .story-heading {
              font-size: 3rem;
              font-weight: 600;
              line-height: 1;
              margin-bottom: 3.2rem;
              color: #0f3787;
              font-family: Verdana, Geneva, sans-serif;

              @media (max-width: 35em) {
                font-size: 2.3rem;
              }
            }

            .grid-box {
              display: grid;
              grid-template-columns: 1fr 1fr;
              gap: 3.2rem;

              @media (max-width: 59em) {
                display: grid;
                grid-template-columns: 1fr;
                gap: 3.2rem;
              }

              .img-box {
                width: 100%;
                height: 100%;
                border-radius: 9px;

                @media (max-width: 59em) {
                  width: 100%;

                  height: 400px;
                }

                img {
                  width: 100%;
                  height: 100%;
                  border-radius: 9px;
                }
              }

              .para-one {
                color: #000;
                text-align: left;
                // font: normal normal normal 24px/32px Times New Roman;
                line-height: 1.6;

                font-size: 19px;
                font-family: "Trebuchet MS";
              }
            }

            .story-content-wrapper {
              // border: 1px solid;
              padding: 20px;

              .para-two {
                color: #000;
                text-align: left;
                // font: normal normal normal 24px/32px Times New Roman;
                line-height: 1.6;

                font-size: 19px;
                font-family: "Trebuchet MS";
              }
            }

            .card-wrapper {
              padding: 10px;
              // border: 1px solid;

              .card {
                width: 50%;
                margin: 0 auto;
                border: none;
                border-radius: 10px;
                box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3);

                .card-body {
                  .box {
                    color: #000;

                    .text {
                      display: flex;

                      .violin-img {
                        height: 65px;
                        width: 65px;
                        // border: 1px solid;
                        border-radius: 10px;
                        display: flex;

                        margin: 20px;
                      }

                      .para-three-cont {
                        .para-three {
                          font-size: 18px;
                          font-weight: 500;
                          color: #0f3787;

                          text-align: left;
                        }
                      }
                    }
                  }
                }
              }
            }

            .para-four-wrap {
              padding: 20px;
              .para-four {
                color: #000;
                text-align: left;
                // font: normal normal normal 24px/32px Times New Roman;
                line-height: 1.6;

                font-size: 19px;
                font-family: "Trebuchet MS";
              }
            }
          }
        }
      }
    }
  }
}
