.leaflet-div-icon1 {
    background: transparent !important;
    border: none;
    height: 30px !important;
    width: 30px !important;
}

.leaflet-div-icon2 {
    background: transparent !important;
    border: none;
    height: 30px !important;
    width: 25px !important;
}
