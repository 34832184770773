.leaflet-popup {
  // border: 2px solid green;

  .leaflet-popup-content-wrapper {
    // border: 2px solid blue;

    .video-popup-wrapper {
      // border: 1px solid gray;
      border-radius: 20px;
      padding: 20px;
      width: 100%;
      height: 325px;

      .video-container video {
        width: 100%;
        height: 280px;
        border: 2px solid #00000029;
        margin-top: -10px;
        border-radius: 15px;
        // margin-left: -40px;
        box-shadow: 0px 3px 16px #00000029;
      }

      .description-container {
        padding: 20px;
        margin-top: 10px;
        // width: 100%;
        border: 2px solid #00000029;
        box-shadow: 0px 2px 10px #0000001a;
        border-radius: 10px;
        opacity: 1;

        .popup-content {
          // display: flex;
          // justify-content: center;

          .popup-title {
            color: #0F3787;
            font-size: 17px;
            font-weight: 600;
          }

          .popup-details {
            text-align: center;
          }
        }

        .featured-badge {
          float: right;

          svg {
            color: #EFC655;
          }
        }
      }
    }
  }
}
