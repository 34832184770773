.mainSearchBoxDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.searchButton {
  // margin-right: 30px;
  // width: 18vw;
  min-width: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border: 1px solid;
  padding: 5px;
  border-radius: 10px;
  gap: 5px;
  border-color: gray;

  // @media only screen and (max-width: 992px) {
  //     margin-top: 10px;
  //     position: relative;
  //     width: 80%;
  //     right: 29px;
  // }
}
.searchButtonImage {
  padding-top: 5px;
  cursor: pointer;
  margin-right: o;
}

.searchButtonMain {
  width: 100%;
  font-size: 14px;
  color: gray;
  border: none;
  height: 1.5rem;
  // background-color: #f3f8fa;

}

.searchButtonMain:focus {
  outline: none;
}
.results {
  position: relative;
}
.relative {
  position: absolute;
  z-index: 9999;
  left: -150px;
  background-color: white;
  // background-color: #f3f8fa;

  // background-color: rgba(113, 164, 194, 0.08);
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
  margin-top: 20px;
  padding-top: 20px;
  // max-width: 5000px;
  padding-left: 10px;
  padding-bottom: 10px;
  border-radius: 0.5rem;
  // max-height: 500px;
  max-height: 400px;
  overflow: hidden;
  overflow-y: scroll;

  @media only screen and (max-width: 500px) {
    max-height: 200px;
  }
}

.relative::-webkit-scrollbar {
  display: none;
}
.relative li {
  list-style: none;
  list-style-type: none;
  padding: 5px 10px;
  border-bottom: 1px solid #ededed;
  // font-size: 44;
  width: 290px;
  font-size: 16px;
}

.results .relative li:hover {
  background-color: #fafafa;
  // background-color: #c3edfd;
  // background-color: white;

  border-radius: 5px;
}

.no-results {
  background-color: white;
  //   background-color: #f3f8fa;
  // background-color: #c3edfd;

  color: gray;
}

.searchButtonImage {
  padding-bottom: 5px;
}
