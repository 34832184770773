
.opt-wrapper {
    border: 1px solid #ced4da;
margin: 10px;
margin-left: 0px;
padding-right: 0px;
padding-left: 0px;
width: 100%;
}

.bgWhite {
  display: flex;
  justify-content: space-around;
}
.title{
    font-weight:500;
    margin-top:20px;
    font-size:16px;
    color: rgba(0, 0, 0, 0.6);
  }
  
  .customBtn{
    border-radius:0px;
    padding:10px;
  }
  
  .otp-form input{
    display:inline-block;
    width:30px;
    height:30px;
    border-radius: 30%;
    margin: 5px;
    /* margin-top: 10px; */
    text-align:center;
  }