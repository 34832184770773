.outbor {
    // border: 1px solid black;
    box-shadow: 1px 2px 3px darkgray;
}

// .sidebar_top {
//     /* background-color: blue; */
//     /* border: 2px solid blue; */
//     /* background-color: white; */
//     /* background: rgb(255,255,255); */
//     /* background: linear-gradient(180deg, rgba(255,255,255,1) 70%, rgba(255,255,255,0.6054796918767507) 100%); */
//     z-index: 999;
//     /* box-shadow: 0px 20px 20px 0px rgb(255 255 255 / 100%); */
// }

// .saveAllLogo {
//     width: 100px;
//     margin-left: 15px;
//     position: relative;
//     top: 5px;
//     height: 50px;
//     margin-bottom: 10px;
//     @media only screen and (max-width: 852px) {
//         width: 15%;
//     }
//     @media only screen and (max-width: 488px) {
//         width: 25%;
//         margin-top: 20px;
//         padding-bottom: 150px;
//     }
// }

// .comingSoon .comesoon {
//     /* font-family: "Trebuchet MS"; */
//     font-family: Verdana, Geneva, sans-serif;
//     font-size: 40px;
//     font-weight: 600;
//     /* color: red; */
//     margin-left: 73px;
// }

// .comingSoon {
//     background-image: url(../assets/images/Banner.JPG);
//     /* min-height: 150px; */
//     height: 100px;
//     width: 100%;
//     background-position: center;
//     background-repeat: no-repeat;
//     background-size: 90% 100%;
//     position: relative;
//     /* border: 3px solid white;s */
//     @media only screen and (max-width: 852px) {
//         width: 50%;
//         height: 100px;
//     }
//     @media only screen and (max-width: 620px) {
//         width: 40%;
//         height: 100px;
//     }
//     @media only screen and (max-width: 488px) {
//         position: absolute;
//         // top: 16.5vh;
//         top: 139px;
//         width: 100vw;
//         right: 2px;
//     }
//     /* @media only screen and (max-width: 552px) {
//         width: 50%;
//         height: 100px;
//     }  */
// }

// .comingSoon .saveAllBanner {
// }

// .pslamLogo {
//     width: 25%;
// }

// .pslamGif {
//     position: relative;
//     width: 100%;
//     height: 100px;
//     @media only screen and (max-width: 852px) {
//         width: 30%;
//         height: 150px;
//     }
//     @media only screen and (max-width: 620px) {
//         width: 5%;
//         height: 100px;
//         overflow: hidden;
//         position: absolute;
//         right: 0.5vw;
//         top: 10px;
//         right: 3%;
//     }
// }

// /* @media only screen and (max-width: 575px) {
//     .pslamLogo{
//         position: relative;
//         bottom: 300px;
//         width: 10px;
//         height: 10px;
//         left: 70vw;
//     }
//     .comingSoon{
//         position: relative;
//         top: 100px;
//     }

// } */

// @media only screen and (max-width: 600px) {
//     .comingSoon .comesoon {
//         /* font-family: "Trebuchet MS"; */
//         font-family: Verdana, Geneva, sans-serif;
//         font-size: 24px;
//         /* margin-right: 55px;
//         margin-top: 15px; */

//         /* color: red; */
//     }
// }

// @media screen and (min-width: 300px) and (max-width: 768px) {
//     .comingSoon .comesoon {
//         /* font-family: "Trebuchet MS"; */
//         font-family: Verdana, Geneva, sans-serif;
//         font-size: 20px;
//         margin-left: 50px;

//         /* border: 1px solid green; */
//         /* color: blue; */
//     }
//     .formContainer {
//         /* height: 100px !important; */
//         position: relative !important;
//         top: 30px !important;
//         /* padding-top: 90px !important; */
//     }
// }

// /* navigation */
// .navbar-collapse {
//     flex-grow: 0 !important;
// }

// .navbar-nav {
//     display: flex;
//     align-items: center;
// }

// .navbar-nav li {
//     padding: 0 10px;
// }

// .navbar-collapse a {
//     /* color: white !important; */
//     font-size: 18px;
// }

// .navbarHeadC {
//     /* color: white; */
//     height: 100px;
// }

// .navbarHeadC a {
//     /* color: white; */
// }

// .upload-btn a button {
//     /* color: green !important; */
//     position: relative;
//     right: 90px;
// }

// ul.navbar-nav.ml-auto {
//     margin-right: 80px;
// }

// .custom-file-button {
//     width: 460px;
//     height: 60px;
//     margin-top: 10px;
//     padding: 10px 15px;
//     border-radius: inherit;
//     border-style: solid;
//     border-width: 1px;
//     overflow: hidden;
//     min-width: 0%;
//     border-color: rgba(0, 0, 0, 0.23);
// }



.navbarHeadC {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // background-color: #f3efe9;
  // background-color: white
  // background-color: #71a4c2;
  // background-color: rgba(113, 164, 194, 0.08);
  // background-color: #f3f8fa;
  background-color: white;

  margin: 0;
  height: 12vw;
  min-height: 70px;
  max-height: 150px;
  .saveAllLogo {
    width: 10vw;
    min-height: 50px;
    max-height: 120px;
  }
  .bannerDiv {
    width: 100%;
    height: 100%;
    margin-top: 10px;
    // border: 1px solid red;

    .banner {
      width: 60vw;
      // height: 9vw;
      // height: 140px;
      min-height: 50px;
      max-height: 150px;
      border-radius: 5px;

      // background-image: url("../assets/images/Home-Banner-Indonesian.jpg");
      // height: 100%;
      // background-position: bottom;
      // background-repeat: no-repeat;
      // background-size: 1400px 145px;
      // width: 100%;
      // border: 1px solid;
    }
  }

  .pslamGif {
    width: 10vw;
    height: 90%;
  }
}
