.upload-outer-wrapper {
  //   border: 3px solid red;

  .upload-wrapper {
    // border: 1px solid;
    background-color: #f5f5f5;
    height: 100%;
    width: 100%;
    padding: 10px;

    .row {
      .upload-btn {
        float: left;
        margin-left: 100px;
        margin-top: 20px;
        margin-bottom: 10px;
        border: none;
        outline: none;
        font-size: 18px;
        font-weight: 500;
        color: #0f3787;
        padding: 10px;
        width: 10%;
        cursor: default;
        // border-radius: 1px;
        // background-color: #0f3787;
        border-bottom: 1px solid #0f3787;
      }
      .upload {
        margin-bottom: 10px;

        .upload-section-one {
          padding: 3%;
          width: 80%;
          background-color: #efefef;
          margin: 0 auto;
          padding-bottom: 10px;

          .upload-header {
            font-size: 22px;
            font-weight: 600;
            color: #0f3787;
            text-align: left;
            font-family: Verdana, Geneva, sans-serif;
          }

          .inner-content {
            padding-left: 20px;
            font-size: 20px;
            font-weight: 400;
            text-align: left;

            font-family: Verdana, Geneva, sans-serif;
          }

          .inst-lang-total-wrap {
            // border: 1px solid;
            text-align: left;
            padding-left: 2%;
            margin-top: 20px;

            .instruction,
            .language,
            .total-video {
              font-size: 2rem;
              line-height: 1.6;
              color: #000;
              text-align: left;
              font-family: Verdana, Geneva, sans-serif;
            }
          }

          .card-column {
            display: flex;
            flex-wrap: wrap;

            .instruction-song-wrap {
              // border: 1px solid;
              text-align: left;
              padding-left: 2%;
              margin-top: 10px;
              margin-bottom: 10px;
              // box-shadow: 0 0 0 0 #000;

              flex: 1;
              margin: 10px;

              @media (max-width: 992px) {
              }

              .card {
                display: flex;
                flex-direction: column;
                height: 100%;
                padding: 10px;
                box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
                .card-header {
                  .instruction-song {
                    color: #0f3787;
                    font-size: 21px;
                    line-height: 1.6;
                    font-family: Verdana, Geneva, sans-serif;

                    svg {
                      width: 40px;
                      height: 40px;
                      color: #0f3787;
                    }
                  }

                  .duration {
                    color: #000;
                    font-size: 18px;
                    line-height: 1.6;
                    font-family: Verdana, Geneva, sans-serif;
                  }

                  .according {
                    color: #000;
                    font-size: 17px;
                    line-height: 1.6;
                    font-family: Verdana, Geneva, sans-serif;
                  }
                }

                .card-body {
                  flex: 1;
                  p {
                    font-size: 2rem;
                    line-height: 1.6;
                    color: #000;
                    text-align: left;

                    font-family: Verdana, Geneva, sans-serif;
                  }
                }
              }
            }
          }

          .leader-suggested-wrap {
            // border: 1px solid;
            padding-left: 20px;

            ul {
              text-align: left;
              margin: 10px;
              // list-style: none;
              li {
                font-size: 18px;
                color: #000;
                margin: 10px;

                font-family: Verdana, Geneva, sans-serif;
              }
            }
          }
        }

        .upload-section-two {
          padding: 3%;
          width: 80%;
          background-color: #efefef;
          margin: 0 auto;
          padding-top: 10px;

          .form-header {
            font-size: 22px;
            font-weight: 600;
            color: #0f3787;
            text-align: left;
            font-family: Verdana, Geneva, sans-serif;
          }

          .form-field-list {
            ul {
              text-align: left;
              list-style: none;

              li {
                font-size: 19px;
                color: #000;
                // margin: 10px;
                margin: 35px;
                font-family: Verdana, Geneva, sans-serif;
              }
            }
          }

          .video-image-wrap {
            text-align: left;
            list-style-type: disc;

            .upload-format {
              color: #0f3787;
              font-family: Verdana, Geneva, sans-serif;
              font-size: 20px;
              font-weight: 600;
            }

            li {
              font-size: 16px;
              color: #000;
              margin: 10px;
              
              font-family: Verdana, Geneva, sans-serif;
            }
          }

          .upload-form-img-wrap {
            //   border: 1px solid;

            h5 {
              color: #0f3787;
              text-align: left;
              padding: 10px;
            }

            // $crimson: #2a60cc;

            @keyframes imgglow {
              0% {
                box-shadow: 0 0 0 0 #2a60cc;
              }

              50% {
                box-shadow: 0 0 20px 0 #2a60cc;
              }
            }

            .form-img {
              // width: 50%;
              display: flex;
              justify-content: left;
              border-radius: 10px;
              // height: 100%;
              margin: 20px;
              //   box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 15px 10px;
              animation: imgglow 1.4s ease-out infinite;
            }
          }

          //   .upload-form-img-wrap {
          //     animation-name: upload-form-img-wrap;
          //     animation-duration: 3s;
          //     animation-iteration-count: infinite;
          //     animation-timing-function: ease-in-out;
          //     margin-left: 30px;
          //     margin-top: 5px;
          // }

          // @keyframes upload-form-img-wrap {
          //     from { transform: translate(0,  0px); }
          //     65%  { transform: translate(0, 15px); }
          //     to   { transform: translate(0, -0px); }
          // }
        }
      }
    }
  }
}
