.mapclass {
  height: calc(85vh - 100px);
  // overflow: hidden;

  // .caption_wrapper {
  //   border: 1px solid;
  //   padding: 15px;

  //   h6 {
  //     color: black;
  //   font-size: 22px;
  //   }
  // }
  .navbar_wrapper {
    border: 1px solid black;
  }

  /* popup close icon */
  .leaflet-popup-close-button {
    position: absolute;
    top: 0;
    right: 0;
    border: 1px solid rgba(0, 0, 0, 0.6) !important;
    border-radius: 50% !important;
    text-align: center;
    width: 35px !important;
    height: 35px !important;
    font: 32px/32px Tahoma, Verdana, sans-serif !important;
    color: rgba(0, 0, 0, 0.6) !important;
    text-decoration: none;
    background: transparent;
    margin: 5px !important;
  }

  #map {
    width: 100% !important;
    // height: 100vh !important;
    height: auto;
    background-color: #ffffff !important;
  }

  .mapContent {
    height: 100%;

    .map-container {
      position: relative;
      height: 100vh;
      width: 100%;
      // overflow: hidden;
    }
  }

  .playerClass {
    display: none;
    /* display: none !important; */
    width: 50%;
    position: absolute;
    bottom: 0;
    text-align: center;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    /* border: 2px solid red; */
    margin-bottom: 40px;
    z-index: 999;

    .playercontainer {
      display: flex;
      /* justify-content: center; */
      align-items: center;
      /* border: 2px solid red; */
      background: #0f3787 0% 0% no-repeat padding-box;
      box-shadow: 0px 0px 36px #00000042;
      border-radius: 14px;
      /* border-radius: 50px; */

      img {
        width: 70px;
        border-radius: 50px;
        padding: 10px;
        height: 70px;
      }
    }
  }

  .playPauseBtn {
    position: absolute;
    top: 100px;
    right: 10px;
    z-index: 99999;
    cursor: pointer;
    display: none;

    img {
      width: 50px;
    }

    #playIconBtn {
      display: none;
    }
  }

  .videoContent {
    position: absolute;
    bottom: 0;
    z-index: 99999;
    z-index: 999;
    padding: 0 !important;
    margin: 0 !important;

    display: none;

    // .video-carousel {
    //   position: relative;
    //   width: 100%;
    //   overflow: hidden;
    // }
    // .video-container {
    //   display: flex;
    //   overflow-x: auto;
    //   scroll-behavior: smooth;
    //   scroll-snap-type: x mandatory;
    //   -webkit-overflow-scrolling: touch;
    // }
    // .video-item {
    //   flex: 0 0 auto;
    //   scroll-snap-align: center;
    //   width: 100%;
    //   margin-right: 10px;
    // }
    // .video-item video {
    //   width: 100%;
    //   height: auto;
    // }
    // .prev-button, .next-button {
    //   position: absolute;
    //   top: 50%;
    //   transform: translateY(-50%);
    //   width: 40px;
    //   height: 40px;
    //   font-size: 24px;
    //   background-color: transparent;
    //   border: none;
    //   color: #000;
    //   cursor: pointer;
    //   z-index: 1;
    // }
    // .prev-button {
    //   left: 10px;
    // }
    // .next-button {
    //   right: 10px;
    // }

    @media only screen and (max-width: 575px) {
      position: absolute;
      bottom: 0;
      z-index: 998;
      padding: 0 !important;
      margin: 0 !important;
    }

    .videoCol {
      padding: 0 !important;
      margin: 0 !important;

      video {
        width: -webkit-fill-available;
        height: auto;
        padding: 25px;
      }
    }

    .hideVid {
      pointer-events: none;
      opacity: 0.7;

      @media only screen and (max-width: 575px) {
        display: none;
      }
    }

    @media only screen and (max-width: 575px) {
      .hideVidMain {
        margin-left: auto !important;
        margin-right: auto !important;
        width: 100% !important;
      }

      .responsive-iframe {
        // border: 1px solid red !important;
        position: relative !important;
        left: 25px !important;
      }
    }

    /* Then style the iframe to fit in the container div with full height and width */
    .responsive-iframe {
      position: relative !important;
      top: 25px !important;
      left: 0;
      bottom: 0;
      // border: 1px solid red !important;
    }

    .videowrapper {
      float: none;
      clear: both;
      width: 90%;
      height: 80%;
      // position: relative;
      // padding-bottom: 56.25%;
      // padding-top: 25px;
    }
    .videowrapper iframe {
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  @media only screen and (max-width: 600px) {
    .leaflet-left {
      display: none !important;
    }
  }

  @media only screen and (min-width: 992px) {
    .container-fluid.videoContent {
      // z-index: 99999;
    }
  }

  .userForm {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
    top: 0;

    .middle {
      width: 90vw;
      max-width: 800px;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 3rem;
      padding: 20px 50px;
      background-color: #0f3787;
      border-radius: 10px;
      // @media {
      //   flex-direction: column;
      //   position: relative;
      //   top: 5%;
      // }
      @media screen and (max-width: 680px) {
        flex-direction: column;
        position: relative;
        top: 5%;
      }
      @media screen and (max-width: 460px) {
        gap: 0.5rem;
      }
      .formContent {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 3rem;
        color: white;
        letter-spacing: 1.5px;
        @media screen and (max-width: 680px) {
          letter-spacing: 1px;
          gap: 1rem;
        }

        .formContent1 {
          font-size: 24px;
          // font-family: ;
          @media screen and (max-width: 680px) {
            font-size: 16px;
          }
        }
        .formContent2 {
          font-size: 1em;
          @media screen and (max-width: 680px) {
            font-size: 14px;
          }
        }
        .formContent3 {
          font-size: 0.8em;
          @media screen and (max-width: 600px) {
            font-size: 12px;
          }
        }
      }
      .formUser {
        background-color: white;
        padding: 3rem 1.5rem;
        border-radius: 10px;
        .btnCol {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 1rem;
          margin-top: 1.5rem;
          margin-bottom: 1rem;
        }
      }
    }
  }

  // SMH
  .leaflet-container {
    height: 79vh;
    @media screen and (max-width: 740px) {
      height: 100vh;
    }

    // height: calc(100vh - 200px);
    // height: calc(100vh - 100px); /* Adjust the value (100px) based on your requirements */
    // background-color: transparent;
  }

  .leaflet-control-attribution.leaflet-control {
    display: none;
  }

  .toastAlert {
    margin-top: 60px;
  }

  .Toastify__toast-container.Toastify__toast-container--top-right.toastAlert {
    z-index: 99999;

    white-space: pre-line;
  }
}

/* gap between map & popup */
.leaflet-popup
// .leaflet-zoom-animated
{
  // border: 1px solid red;
  opacity: 1;
  transform: translate3d(449px, 29px, 0px);
  bottom: 0px !important;
  left: -290px !important;
}
.pslamLogo img {
  width: 160px;
}

.custom-leaflet-popup {
  // width: 100%;
  // max-width: 400px;
  // max-height: 70vh;
  // overflow-y: auto;

  // border: 2px solid red;
}

.leaflet-popup-content-wrapper {
  width: 550px;
  position: relative;
  // z-index: 1000; /* Adjust the z-index value as needed */
  padding: 10px;
  height: 500px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  .leaflet-popup-content {
    padding: 20px;
    margin: 0 auto;
    width: 500px !important;
    position: static;

    .marked-location-userdata {
      height: 50px;
      width: 200px;
      // border: 1px solid red;
    }

    .userdata-uploadform {
      // border: 2px solid red !important;
      width: 100% !important;

      .form-control {
        // border: 1px solid green;
        .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
          padding: 5px;
        }
        .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
          // border: 1px solid rebeccapurple;
          width: 100%;
        }
      }

      /* Chrome, Safari, Edge, Opera */
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      input[type="number"] {
        -moz-appearance: textfield;
      }
      .container {
        margin-top: 10px;
        // margin-bottom: 10px;

        background-color: #fff;
      }
      .card-footer {
        margin-top: 10px;
      }
      .uploadform-errormsg {
        color: red;
      }
      .uploadform-errormsg2 {
        color: red;
        border: 1px solid;
        margin-top: 10px !important;
        margin-left: 5px !important;
        padding: 100px;
        // border: 1px solid;
      }

      .uploadform-successmsg {
        color: green;
      }

      #fileSizeMessage {
        height: 25px;
        // width: 100px;
        // font-size: 13px;
        color: red;
        // position: relative;
        right: 100px;
        // bottom: 21px;
        margin-left: 9px;
      }

      .requestOtpBtn {
        margin-top: 10px;
      }
    }
  }

  .upload-submit-btn {
    // color: red;
    // border: 1px solid red;
    display: flex;
    justify-content: end;
    margin-right: 20px;
    margin-top: 10px;

    button {
      color: #002daa !important;
      border: 1px solid #002daa !important;

      &:hover {
        color: #fff !important;
        background-color: #002daa !important;
      }
    }
  }
  // .confirm-head {
  //   color: #002daa;
  // }
}

#one {
  font-size: 16px;
  width: 120px;
  border: none;
  border-bottom: 1px solid black;
  border-radius: 0;
  letter-spacing: 18px;
  text-align: center;
  padding: 5px;
}

#one:focus {
  outline: none;
  border: none;
  border-bottom: 1px solid #0f3787;
}

$crimson: #2a60cc;

.center {
  position: absolute;
  top: 82%;
  left: 5%;
  z-index: 999 !important;
  transform: translate(-50%, -50%);

  height: 30%;
  width: 80px;
  // border: 1px solid green !important;
  border-radius: 5px;
  cursor: default;
}

@keyframes glow {
  0% {
    box-shadow: 0 0 0 0 $crimson;
  }

  50% {
    box-shadow: 0 0 30px 0 $crimson;
  }
}

.btn {
  background: #fff !important;
  color: inherit;
  font: inherit;
  border: none;
  outline: none;
  cursor: pointer;
  color: hsl(224, 74%, 64%) !important;
}

.btn-glow {
  background: white;
  padding: 0px !important;
  cursor: default;

  .video-carousal-before {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: right;

    .carousal-inner {
    }
    svg {
      color: black;
      height: 30px;
      width: 30px;
      position: absolute;
      left: 65px;
      background: #fff;
      border-radius: 50%;
      cursor: pointer !important;
      box-shadow: 0 0 0 0 $crimson;
      animation: glow 1.4s ease-out infinite;
    }
  }
}

// --------------------Carousel--------
.testing {
  // bordeR:1px solid red !important;
  // border-radius: 2.7vh !important;
  // cursor: pointer;
  // z-index: 999 !important;
  // position: relative;
  // padding: 10px !important;
  // background-color: #fff;
  // left: 90% !important;
  // padding-top: 15px !important;
  cursor: pointer;
  z-index: 999 !important;
  position: relative;
  background-color: #fff;
  left: 92% !important;
  padding: 8px;
  border-radius: 50%;
  bottom: 10px;
  .closeIcon {
    color: #000000;
    font-size: 15px !important;
  }
}
// .carousels {
//   z-index: 999 !important;
//   background-color: #94abdb;
//   position: absolute !important;
//   text-align: start !important;
//   width: 20% !important;
//   max-width: 300px;
//   // height: 310px;
//   bottom: 10px !important;
//   left: 3vh;
//   padding-bottom: 15px;

//   //  bordeR:2px solid red !important ;

//   #carouselExampleIndicators {
//     //  height: 50% !important;
//     //   width: 90% !important;

//     background-color: transparent !important;
//   }
//   .carousel-inner {
//     // border: 1px solid red !important;
//     background-color: transparent !important;
//   }

//   iframe {
//     width: 100% !important;
//     //  height: 250px !important;
//   }
// }

.carousels {
  z-index: 999 !important;
  background-color: #94abdb;
  position: absolute !important;
  text-align: start !important;
  width: 50% !important; /* Set the initial width to 50% */
  max-width: 300px;
  height: auto !important; /* Set the height to auto */
  bottom: 10px !important;
  left: 3vh;
  padding-bottom: 15px;

  #carouselExampleIndicators {
    background-color: transparent !important;
  }

  .carousel-inner {
    background-color: transparent !important;
  }

  iframe {
    width: 100% !important; /* Ensure the iframe width is set to 100% */
    height: auto !important; /* Allow the iframe height to adjust automatically */
  }
}

.expanded {
  width: 100% !important; /* Set the expanded width to 100% */
  height: auto !important; /* Allow the height to adjust automatically when expanded */
}

.asd {
  // border:3px solid rgb(17, 175, 30) !important;
  // width: 50% !important;
  // height: 50vh !important;
  margin-top: -2.2vh !important;

  // bordeR:2px solid red !important;
}
.carousel-item {
  // bordeR:2px solid red !important;

  background-color: transparent !important;
}
.C_Fvideos {
  // border:3px solid red ;

  text-align: start;
  display: flex;
  // justify-content: flex-start;
  justify-content: center;
  // width: 120px;
  gap: 9px;
  align-items: center;
  flex-direction: row;
  margin-top: 10px;
  width: 100%;
  flex-direction: row;

  .videos {
    text-align: center;
    padding: 7px;
    border-radius: 5px;
    background-color: rgb(207, 218, 207) !important;
    width: 30%;
    max-width: 35%;
    box-shadow: 0px 2px 4px 0px rgba (90, 88, 88, 0.25) inset,
      2px 0px 4px 0px rgba(0, 0, 0, 0.25) inset !important;
  }
  .videos a {
    color: #0d0d0e !important ;
    text-decoration: none !important;
    font-size: 16px;
    font-weight: 500;
  }
}
.C_Fvideos .active {
  background-color: #ffffff !important;

  color: white !important;
}
.C_Fvideos .active .CF {
  color: #002daa !important;
}

.carousel-control-prev-icon {
  border-radius: 2vh !important;
  background-color: #0f3787 !important;
  // font-size: 12px !important;
  position: relative;
  top: -10px !important;
  //  margin-top: -85vh !important;
  // margin-top: -64px !important;
  // bordeR:3px solid red !important;
}
.Arrows {
  // bordeR:3px solid red !important;
  font-size: 2px !important;
}
.nextArrow {
  font-size: 2px !important;
}
.carousel-control-next-icon {
  border-radius: 2vh !important;
  background-color: #0f3787 !important;

  position: relative;
  top: -15px !important;
  // margin-top: -54px !important;
}
icon,
.carousel-control-prev-icon {
  background-size: 58% 80% !important;
}
icon,
.carousel-control-next-icon {
  background-size: 58% 80% !important;
}
@media screen and (max-width: 1045px) {
  .carousels {
    width: 100% !important;
    // bordeR:2px solid red !important ;
  }
  #carouselExampleIndicators {
    width: 100% !important;
  }
}
