.knowinggod-outer-wrapper {
  //   border: 3px solid red;
  .knowinggod-wrapper {
    // border: 1px solid;
    background-color: azure;
    height: 100%;
    padding: 10px;

    .row {
      .knowinggod {
        margin-bottom: 10px;

        .knowinggod-btn {
          float: left;
          margin-left: 100px;
          margin-top: 20px;
          border: none;
          outline: none;
          font-size: 18px;
          font-weight: 500;
          color: #0f3787;
          padding: 10px;
          width: 15%;
          border-radius: 10px;

        }
      }

      .content-wrapper {
        // border: 1px solid green;

        .box-one-knowinggod {
          width: 80%;
          // border: 1px solid;
          margin: 0 auto;
          text-align: justify;

          .know-god-head {
            text-align: center;
            color: #0f3787;
          }

          .know-god-para-wrap {
            border: 1px solid;
          }
        }
      }
    }
  }
}
