.aboutus-outer-wrapper {
    //   border: 3px solid red;
      .aboutus-wrapper {
        border: 1px solid;
        height: 100%;
        padding: 10px;
    
        .row {
          .aboutus {
    
            .aboutus-btn {
              float: left;
              margin-left: 100px;
              margin-top: 20px;
              border: none;
              outline: none;
              font-size: 18px;
              font-weight: 500;
              color: #0f3787;
              padding: 10px;
              width: 130px;
              border-radius: 10px;
            }
          }
    
          .content-wrapper {
            // border: 1px solid green;
    
            .box-one-about {
                width: 50%;
                // border: 1px solid;
                margin: 0 auto;
                text-align: justify;
            }
          }
        }
      }
    }
    