
#countdown {
	width: 270px;
	height: 65px;
	text-align: center;
	font: bold 26px 'Droid Sans', Arial, sans-serif;
	/* font-family:'digital-clock-font'; */
	color: #111;
	background-color: #ddd;
	background-image: -webkit-linear-gradient(top, #bbb, #eee); 
	background-image:    -moz-linear-gradient(top, #bbb, #eee);
	background-image:     -ms-linear-gradient(top, #bbb, #eee);
	background-image:      -o-linear-gradient(top, #bbb, #eee);
	border: 1px solid #111;
	border-radius: 5px;
	box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.7);
	display: inline-block;
	position: relative;
	right: 75px;
	margin: auto;
	padding: 15px;

} 


#countdown:before{
	content:"";
	width: 280px;
	height: 20px;
	background: #111;
	display: block;
	padding: 0 3px;
	position: absolute;
	top: 31%;
	left: -7px;
	z-index: -1;
}

#countdown:after{
	content:"";
	width: 100%;
	height: 1px;
	background: #eee;
	border-top: 1px solid #111;
	display: block;
	position: absolute;
	top: 48%; left: 0;
}




@media screen and (min-width: 300px) and (max-width: 768px) {

#countdown {
	width: 220px;
	height: 65px;
	text-align: center;
	font: bold 24px 'Droid Sans', Arial, sans-serif;
	color: #111;
	background-color: #ddd;
	background-image: -webkit-linear-gradient(top, #bbb, #eee); 
	background-image:    -moz-linear-gradient(top, #bbb, #eee);
	background-image:     -ms-linear-gradient(top, #bbb, #eee);
	background-image:      -o-linear-gradient(top, #bbb, #eee);
	border: 1px solid #111;
	border-radius: 5px;
	box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.7);
	display: inline-block;
	position: relative;
	/* right: 50px; */
	left: 0px;
	margin: auto;
	padding: 15px;

} 


#countdown:before{
	display: none;
}

#countdown:after{
	content:"";
	width: 100%;
	height: 1px;
	background: #eee;
	border-top: 1px solid #111;
	display: block;
	position: absolute;
	top: 45%; left: 0;
}


}