/* ------- */
/* CONTACT */
/* ------- */

.contact {
  padding: 10px;
  background: transparent;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.contact-form-box {
  /* border: 1px solid green; */
  padding: 37px;
  background-color: #fff;
  border-radius: 15px;

  width: 50%;
  margin: 0 auto;
}

.form-required {
  color: red;
}

/* ---- */
/* FORM */
/* ---- */

.contact form {
  /* display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px; */
  width: 100%;
  padding: 20px;
  margin: 0 auto;
  margin-bottom: 10px;
  border: 1px solid #cccccc;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 15px 10px;
}

.field-wrap {
  /* border: 1px solid; */
  margin: 0 auto;
  margin-top: 20px;
  text-align: left;
  /* width: 50%; */

}

.FormErrors {
  color: red;
}

.contact form label {
  display: block;
  font-size: 16px;
  font-weight: 600;
}

.contact form input {
  width: 100%;
    height: 50px;
    /* margin: 0 auto; */
    padding: 1em;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #cccccc;
    border-radius: 5px;
    opacity: 1;
    outline: none !important;
}

textarea {
  width: 100%;
  padding: 1em;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #cccccc;
  border-radius: 5px;
  opacity: 1;
  resize: none;
  outline: none !important;
}
.submit-btn-wrap {
  margin-top: 10px;
  margin-bottom: 30px;
}

.submit-btn {
  color: #fff;
  width: 30%;
  font-size: 16px;
  font-family: "Roboto";

  font-weight: 500;
  background-color: #004493;
  height: 40px;
  border: none;
  border-radius: 10px;
  float: right;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

/* ------------- */
/* MEDIA QUERIES */
/* ------------- */

@media only screen and (min-width: 700px) {
  .wrapper {
    display: grid;
    grid-template-columns: 1fr 2fr;
  }

  .wrapper > * {
    padding: 2em;
  }

  .company-info {
    border-radius: 4px 0 0 4px;
    margin-top: -75px;
  }

  .contact {
    border-radius: 0 4px 4px 0;

    /* width: 85%; */
    /* margin-left: 150px; */
  }

  .company-info h3,
  .company-info ul,
  .brand {
    text-align: left;
  }
}

@media only screen and (min-width: 700px) and (max-width: 1050px) {
  .wrapper {
    display: block;
    /* grid-template-columns: 1fr 2fr; */
  }
}

@media only screen and (min-width: 300px) and (max-width: 600px) {

  .contact-form-box {
    /* border: 1px solid green; */
    padding: 25px;
  }

  .company-info {
    margin-top: 50px;
    margin-left: 0px;
    text-align: center;
  }

  .ServicesTag1 {
    padding: 0px;
    font-size: 32px;
    padding-bottom: 20px;
  }

  .contact {
    width: 100%;
    padding: 0px;
    /* border: 1px solid red; */
    margin-top: 0px !important;
    height: 100%;
  }
  .contact-details {
    text-align: left;
    /* text-align: justify; */
    /* word-break: break-all; */
    font-size: 21px;
    width: 90%;
    margin: 0 auto;
    padding-bottom: 25px;
  }
 

}

@media only screen and (min-width: 100px) and (max-width: 900px) {
  .contact {
    width: 100%;
    /* border: 1px solid red; */
    /* margin-top: 100px; */
  }

  .submit-btn {
    color: #fff;
    width: 100%;
    font-size: 16px;
    font-weight: 600;
    background-color: #004493;
    height: 40px;
    border: none;
    border-radius: 10px;
    float: right;
  }
}

@media only screen and (min-width: 820px) and (max-width: 1180px) {
  .submit-btn {
    color: #fff;
    width: 40%;
    font-size: 16px;
    font-weight: 600;
    background-color: #004493;
    height: 40px;
    border: none;
    border-radius: 10px;
    float: right;
  }
}
