
/* html {
  font-size: 62.5%;
} */

.container {
  max-width: 90%;
  margin: 0 auto;
  background-color: azure;
}


.heading-primary {
  font-size: 3.6rem;
  line-height: 1;
  margin-bottom: 3.2rem;
}



.margin-bottom-md {
  margin-bottom: 4.8rem !important;
}

.margin-top-md {
  margin-top: 4.8rem !important;
}


.margin-bottom-vsmall {
  margin-bottom: 1.6rem !important;
}

.highlight {
  font-weight: 600;
  /* color: var(--accent-color) !important; */
}
.image {
  width: 100%;
  height: 100%;
}
.underline-blue {
  display: inline-block;
  border-bottom: 1px solid var(--accent-color); /* You can change the color as needed */
  padding-bottom: 2px;
}
.underline-white {
  display: inline-block;
  border-bottom: 2px solid #f7f7f7; /* You can change the color as needed */
  padding-bottom: 0.25px;
}

.para {
  font-size: 2rem;
  line-height: 1.6;
  color: var(--primary-text-color);

  text-align: left;
}

.para a {
  color: inherit;
  font-weight: 700;
  outline: none;
}

.btn {
  display: inline-block;
  text-decoration: none;
  font-size: 2rem;
  font-weight: 600;
  color: inherit;
  padding: 1.6rem 3.2rem;
  border-radius: 9px;
  border: 1.5px solid var(--accent-color);
  cursor: pointer;
  font-family: inherit;
  color: #000;
}

.btn:hover,
.btn:active {
  background-color: var(--accent-color);
}

.center-text {
  text-align: center;
  
}









.box .fas {
  font-size: 25px;
  height: 40px;
  width: 40px;

  color: #fff;
}

.box .text {
  position: relative;

  width: 100%;

  background-color: var(--accent-color);
  border-radius: 3px;
  transition: 0.4s;
  border-radius: 10px;
}

.box .text .fa1 {
  position: absolute;
  top: 0;
  left: 0;
}

.box .text div {
  width: 100%;
  padding: 1rem 3rem;
  line-height: 2;
}
.box .text div .para {
  color: #fff;
}









/* knowing god styles */

.heading-knowing-god {
  font-size: 3rem;
  color: var(--secondary-text-color);
  font-weight: 700;
  margin-bottom: 4rem;
}
/* common styles */

.container,
.section-about .container,
.section-psalm-67 .container,
.section-knowing-god .container {
  padding: 3.2rem;
  border-radius: 9px;
}

.para,
.section-about .para,
.section-psalm-67 .para,
.section-knowing-god .para {
  color: var(--secondary-text-color);
}

/* media queries */

@media (max-width: 75em) {
  html {
    font-size: 56.25%;
  }
}
