.sidebar_top_wrapper {
  height: 100%;
  /* background-color: rgba(113, 164, 194, 0.08); */
  background-color: white;

  /* position: absolute;
  z-index: 99999999;
  top: 270px;
  left: -20px; */
}

.text-primary {
  color: #5a8dee !important;
}

.shadow-md {
  box-shadow: 0px 5px 10px rgba(90, 116, 148, 0.3);
}

.navbar {
  border-radius: 5px;
}

.sidebarDiv {
  margin-right: 10px;

  @media only screen and (max-width: 992px) {
    margin-bottom: 10px;
  }
}

.caption_wrapper {
  border: 1px solid;
  padding: 15px;
  width: 100%;
  background-color: transparent;
}

.hhh {
  text-align: center;
}

.LangTranslate-comp {
}

.sidebar {
  width: 280px;
  min-height: 100vh;
  box-shadow: 0px 4px 8px rgb(0 0 0 / 16%);
  background-color: #fff;
  position: fixed;
  /* top: 0; */
  top: 170px;
  left: -100%;
  z-index: 9999;
  transition: 0.5s;
}

.sidebar.active {
  left: 0;
  color: #5a8dee !important;
}

.sd-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
}

.sidebar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0,0,0,0.4); */
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
}

.sidebar-overlay.active {
  opacity: 1;
  visibility: visible;
}

.sd-body {
  padding: 15px;
  max-height: calc(100vh - 67px);
  overflow-x: hidden;
}

.sd-body ul {
  display: inline-block;
  width: 100%;
  margin-bottom: 0;
  padding: 0;
}

.sd-body ul li {
  list-style: none;
  margin-bottom: 30px;
}

.sd-body ul li a:hover {
  background-color: #5a8dee;
  color: #fff;
}

.sd-body ul li .sd-link {
  display: flex;
  width: 100%;
  padding: 10px 16px;
  color: #475f7b;
  background-color: #e5e8ec;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
  justify-content: center;
  gap: 10px;
}

li.sd-link:hover {
  color: #0f3787;
  background: #f0f0f0;
  width: 100%;
  height: auto;
}
