.contactus-outer-wrapper {
  // background-color: #cccccc;
  // border: 1px solid red;
  box-shadow: 1px 2px 3px darkgray;

  margin-top: 20px;

  .contactus-wrapper {
    // border: 1px solid;
    height: 100%;
    padding: 10px;
    background-color: #F5F5F5;

    .row {
      .contactus {
        .contactus-btn {
          float: left;
          margin-left: 100px;
          margin-top: 20px;
          margin-bottom: 10px;
          border: none;
          outline: none;
          font-size: 18px;
          font-weight: 500;
          color: #0f3787;
          padding: 10px;
          width: 10%;
          cursor: default;
          // border-radius: 1px;
          // background-color: #0f3787;
          border-bottom: 1px solid #0f3787;
        }
      }

      .contactus-field-wrapper {

        box-shadow: 1px 2px 3px darkgray;
        background-color: #cccccc;
        background-image: url("../../assets//images//contactus_bgimg.jpg");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        width: 90%;

        .contactus-field-cont {
          border: 1px solid;
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: 20px;

          .name-field-wrap {
            border: 1px solid gray;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            label {
              font-weight: 600;
              color: #0f3787;
            }
            .form-required {
              color: red;
            }
            .input-field {
              width: 50%;
              padding: 10px;
              height: 50px;
              background: #ffffff 0% 0% no-repeat padding-box;
              border: 1px solid #cccccc;
              border-radius: 5px;
              opacity: 1;
              outline: none;
            }
          }
        }
      }
    }
  }
}
