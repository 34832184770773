
  
  .popup-container {
    /* position: fixed;
    top: 0;
    left: 0; */
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    overflow: auto;
}
  
  .popup {
    background-color: transparent !important;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    
  }
  
  
  .close-button {
    /* position: absolute; */
    /* top: 10px; */
    /* right: 10px; */
    background-color: #0D6EFD;
    cursor: pointer;
    color: #fff;
    float: right;
    border: none;
    outline: none;
    padding: 2px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
  }

.upload-popup-head {
  font-size: 16px;
  font-weight: 500;
  color: #0D6EFD;
  margin-top: 10px;
}

  
  @media (max-width: 768px) {
    .sidebar {
      width: 100%;
      height: auto;
    }
  }
  